@font-face {
  font-family: 'DIN W01 Regular';
  src: url('../src/DIN 2014 Light.woff2') format('woff2');
  /* src: url('../src/Oblivian-Text-UltraLight.ttf') format('ttf'); */
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'DIN W01 Regular','Calibri','Helvetica Nue',Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}
